<template>
  <main class="category communication-category page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''" v-if="supplierData">
    <div class="category__top-wrapper">
      <h1 class="title category__title fs32">
        {{ supplierData.name }}
      </h1>
      <div
        :style="{ 'background-color': supplierData.logo_background_color }"
        class="communication-header_logo"
      >
        <img class="img" :src="supplierData.image" alt="" />
      </div>
    </div>

    <section class="category__switch">
      <template
        v-for="(productCategory) in supplierProductCategories"
      >
        <a
          :key="'pc_' + productCategory.code"
          class="link category__link pointer"
          :class="{
            category__link_active:
              productCategory.code === currentSupplierProductCategory,
          }"
          v-if="productCategory.data && productCategory.data.length > 0"
          @click="currentSupplierProductCategory = productCategory.code"
        >
            {{ productCategory.label }}
        </a>
      </template>
    </section>
    <section v-show="currentSupplierProductCategoryItems && currentSupplierProductCategoryItems.length > 0">
      <div class="category__container">
        <CategoryUnit
          v-for="(product_item) in currentSupplierProductCategoryItems"
          :key="'pc_pi_' + product_item.id"
          class="category__unit"
          :productItem="product_item"
          :supplierData="supplierData"
          :productData="productData"
          :supplierId="supplierId"
          :isSupplierCategoryItem="true"
        ></CategoryUnit>
      </div>
    </section>

    <section v-if="notCurrentSupplierProductCategoryItems && notCurrentSupplierProductCategoryItems.length > 0" class="category__more">
      <h2 class="title category__title-h2">
        {{ $t('GIFTCARDSUP.ADDITIONAL_PRODUCTS') }}
      </h2>
      <div class="category__wrapper">
        <template
          v-for="(product_item) in notCurrentSupplierProductCategoryItems"
        >
          <CategoryUnit
            :key="'pi_' + product_item.id"
            class="category__unit"
            :productItem="product_item"
            :supplierData="supplierData"
            :productData="productData"
            :supplierId="supplierId"
            :isSupplierCategoryItem="false"
          ></CategoryUnit>
         </template>
      </div>
    </section>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CategoryUnit from '../../components/communication/CategoryUnit'

export default {
  name: 'CommunicationSupplier',
  components: {
    Loading,
    CategoryUnit 
  },
  data() {
    return {
      currentSupplierProductCategory: null,
    };
  },
  computed: {
    ...mapState({
      loading: state => state.supplier.loading,
      supplier_items: (state) => state.supplier.supplier_items,
      suppliers: (state) => state.supplier.suppliers,
      suppliers_products: (state) => state.supplier.suppliers_products,
      product_tags: (state) => state.supplier.product_tags,
    }),
    supplierId() {
      return this.$route.params.id;
    },
    supplierData() {
      let supplierDataFromSuppliersItemsData = null;
      if (this.supplier_items) {
        for (let supplier_type in this.supplier_items) {
          for (let suppliers_type_item of this.supplier_items[supplier_type]) {
            if (suppliers_type_item.id == this.supplierId) {
              supplierDataFromSuppliersItemsData = { ...suppliers_type_item };
              break;
            }
          }
        }
      }

      let supplierDataFromSuppliersData = null;
      if (this.suppliers && this.supplierId in this.suppliers) {
        supplierDataFromSuppliersData = { ...this.suppliers[this.supplierId] };
      }

      let supplierData = supplierDataFromSuppliersData
        ? supplierDataFromSuppliersData
        : supplierDataFromSuppliersItemsData;
      return supplierData ? supplierData : null;
    },
    supplierProductsAll() {
      let supplierProducts = [];
      if (
        this.suppliers_products &&
        this.supplierId in this.suppliers_products
      ) {
        let supplerProductsByType = this.suppliers_products[this.supplierId];

        if (supplerProductsByType) {
          for (let supplier_product_type in supplerProductsByType) {
            for (let supplier_product of supplerProductsByType[
              supplier_product_type
            ]) {
              supplierProducts.push(supplier_product);
            }
          }
        }
      }

      return supplierProducts ? supplierProducts : [];
    },
    productData() {
      let productData = [];

      if (this.suppliers_products) {
        for (let supplier_id in this.suppliers_products) {
          for (let supplier_product_type in this.suppliers_products[
            supplier_id
          ]) {
            for (let supplier_product of this.suppliers_products[supplier_id][
              supplier_product_type
            ]) {
              if (
                supplier_product.hasOwnProperty('id')
              ) {
                productData.push(supplier_product);
              }
            }
          }
        }
      }
      return productData;
    },
    supplierProductCategories() {
      let supplierProductCategories = [];
      let favoriteCategoryCode = 'favorite';
      let favoriteSupplierProducts = this.filterSupplierProducts(favoriteCategoryCode, true);
      if (favoriteSupplierProducts && favoriteSupplierProducts.length > 0) {
        supplierProductCategories.push({
          label: this.$t('DASHBOARD.FAVORITES'),
          code: favoriteCategoryCode,
          data: favoriteSupplierProducts
        });

        this.setCurrentSupplierProductCategoryIfEmpty(favoriteCategoryCode);
      } else {
        this.unsetCurrentSupplierProductCategoryIfEqual(favoriteCategoryCode);
      }

      for (let tag_index in this.product_tags) {
        let tag = this.product_tags[tag_index];
        let filteredProductsByTag = this.filterSupplierProducts('tag', tag.id);

        if (filteredProductsByTag && filteredProductsByTag.length > 0) {
          supplierProductCategories.push({
            label: tag.name, // add languages on the backend or translations
            code: tag.id,
            data: filteredProductsByTag
          });

          this.setCurrentSupplierProductCategoryIfEmpty(tag.id);
        } else {
          this.unsetCurrentSupplierProductCategoryIfEqual(tag.id);
        }
      }

      return supplierProductCategories;
    },
    currentSupplierProductCategoryItems() {
      let selectedProductsCategoryItems = [];
      if (this.currentSupplierProductCategory
        && this.supplierProductCategories
        && this.supplierProductCategories.length > 0) {
        let selectedProductsCategory = this.supplierProductCategories.find(spc => spc.code === this.currentSupplierProductCategory);

        if (selectedProductsCategory) {
          selectedProductsCategoryItems = selectedProductsCategory.data || [];
        }
      }

      return selectedProductsCategoryItems;
    },
    notCurrentSupplierProductCategoryItems() {
      let notCurrentSupplierProductCategoryItems = [];

      if (this.supplierProductsAll && this.currentSupplierProductCategoryItems) {
        let currentSupplierProductCategoryItemsIds = this.currentSupplierProductCategoryItems.map(p => p.id);
        notCurrentSupplierProductCategoryItems = this.supplierProductsAll.filter(
          sp => !currentSupplierProductCategoryItemsIds.includes(sp.id)
        );
      }

      return notCurrentSupplierProductCategoryItems;
    },
  },
  created() {
    this.getSupplier({ supplier_id: this.supplierId });
    this.getSupplierProducts({ supplier_id: this.supplierId });
    this.getProductTags();
  },
  methods: {
    ...mapActions('supplier', {
      getSupplier: 'getSupplier',
      getSupplierProducts: 'getSupplierProducts',
      getProductTags: 'getProductTags',
    }),
    setCurrentSupplierProductCategoryIfEmpty(category) {
      this.currentSupplierProductCategory = this.currentSupplierProductCategory || category;
    },
    unsetCurrentSupplierProductCategoryIfEqual(category) {
      this.currentSupplierProductCategory = this.currentSupplierProductCategory === category
        ? null : this.currentSupplierProductCategory;
    },
    filterSupplierProducts(field, value) {
      let filteredSupplierProducts = [];
      if (!value || !field || !this.supplierProductsAll) {
        return filteredSupplierProducts;
      }

      if (field === 'tag') {
        filteredSupplierProducts = this.supplierProductsAll.filter(
          (product) =>
            product.hasOwnProperty('tags') &&
            product.tags.length &&
            product.tags.includes(value),
        );
      } else {
        filteredSupplierProducts = this.supplierProductsAll.filter(
          (product) => product.hasOwnProperty(field) && product[field] == value,
        );
      }

      return filteredSupplierProducts;
    },
  },
};
</script>

<style lang="scss">
.communication-header_logo {
  display: none;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  img {
    width: 60px;
  }
}

.category__switch {
  margin-top: 15px;
}
.category__more {
  margin-top: 15px;
}

@media screen and (max-width: 767px) {
  .category__wrapper {
    justify-content: flex-start;
  }
  .category {
    padding: 10px 0;
  }
  .category__top-wrapper {
    position: relative;
    .category__title {
      position: absolute;
      right: 20px;
      top: 0;
      font-size: 18px;
    }
    .communication-header_logo {
      display: flex;
    }
  }
  .category__switch {
    margin-top: 10px;
  }
  .category__more {
    margin-top: 10px;
  }
}
</style>

<style lang="scss" scoped>
.category__link {
  font-size: 18px;
  white-space: nowrap;
}
.ltr-type {
  @media (min-width: 1365px) {
    .category__unit:not(:nth-child(4n)) {
      margin-right: 15px;
      margin-left: 0;
    }
  }
}
</style>